import { useAccountStore } from "@/modules/account/store"
import { watch } from "vue"
import { useAppConfigStore } from "@/store/appConfig"

function loadExternalScript(url: string) {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.src = url
  script.async = true

  script.onerror = () => {
    console.error(`Error loading script: ${url}`)
  }

  document.head.appendChild(script)
}

loadExternalScript(import.meta.env.VITE_HUBSPOT_CHAT_WIDGET)
if (import.meta.env.VITE_HUBSPOT_TRACKING !== import.meta.env.VITE_HUBSPOT_CHAT_WIDGET) {
  loadExternalScript(import.meta.env.VITE_HUBSPOT_TRACKING)
}

export const initHubspot = () => {
  const accountStore = useAccountStore()
  const appConfigStore = useAppConfigStore()

  const loadWidget = async () => {
    const status = window.HubSpotConversations?.widget.status()

    if (status?.loaded) {
      return
    }
    if (!Object.keys(appConfigStore.settings).length) {
      return
    }
    if (appConfigStore.settings.hide_intercom_chat) {
      return
    }
    const visitorToken = await accountStore.getHubspotVisitorToken()

    window.hsConversationsSettings = {
      identificationEmail: accountStore.email,
      identificationToken: visitorToken,
    }

    window.HubSpotConversations?.widget.load()
  }

  function onConversationsAPIReady() {
    watch([() => accountStore.hubspotVisitorToken, () => appConfigStore.settings], () => loadWidget(), { immediate: true })
  }
  window.hsConversationsSettings = {
    loadImmediately: false,
  }

  if (window.HubSpotConversations) {
    onConversationsAPIReady()
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }
}

export const removeHubspot = () => {
  window.HubSpotConversations?.widget.remove()
}
